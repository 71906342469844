import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';

import '../../generic-page.scss';
import './guides.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

const ZZZGameModes: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page zzz guide-zzz'} game="zzz">
      <ul className="breadcrumb">
        <li>
          <Link to="/zenless/">Zenless Zone Zero</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/zenless/guides/">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Game Modes</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/zzz/categories/category_modes.png"
            alt="Characters"
          />
        </div>
        <div className="page-details">
          <h1>Game Modes</h1>
          <h2>All available game modes in Zenless Zone Zero showcased.</h2>
          <p>
            Last updated: <strong>13/06/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <div className="page-content">
        <SectionHeader title="Game modes" />
        <p>
          Zenless Zone Zero features several game modes that will be showcased
          in this guide.
        </p>
        <SectionHeader title="Resource/Boss Stages" />
        <StaticImage
          src="../../../images/zzz/guides/farming.webp"
          alt="Guide"
        />
        <p>
          In Zenless Zone Zero, you will have to clear instance based stages
          with either the bosses we met during story or just regular enemies, to
          obtain resources and materials needed to progress your account.
          However, <strong>Battery Charges</strong> (Stamina) must be used in
          order to enter the stages and claim your rewards.
        </p>
        <p>The different stages are:</p>
        <h5>Combat Simulation</h5>
        <StaticImage
          src="../../../images/zzz/guides/farming_2.webp"
          alt="Guide"
        />
        <p>
          This mode is basically the Zenless Zone Zero equivalent of Golden and
          Crimson Calyx from Honkai: Star Rail. These are your basic resource
          stages and provide one of the following material depending on what
          stage you are farming:
        </p>
        <ul>
          <li>
            <strong>Basic Material</strong> - Character EXP material, Dennies
            (in game currency) and Weapon EXP material,
          </li>
          <li>
            <strong>Agent Promotion</strong> - Ascension materials for
            characters,
          </li>
          <li>
            <strong>Agent Skill</strong> - Ascension materials for character
            skills,
          </li>
          <li>
            <strong>Modify W-Engine</strong> - Ascension materials for character
            weapons,
          </li>
          <li>
            <strong>Custom</strong> - Here you can basically pick any
            combination of materials you wish to farm and mix them into a single
            stage.
          </li>
        </ul>
        <p>
          It costs <strong>20 Battery Charges</strong> to do one Combat
          Simulation stage.
        </p>
        <p>
          You can queue up to 5 Combat Simulation runs at a time. Essentially,
          this allows you to repeat the same Combat Simulation stage without
          having to exit and re-enter. Each run will cost you 20 Battery Charges
          (100 Battery Charges total if you queue up 5 runs).
        </p>
        <h5>Driver Validation</h5>
        <StaticImage
          src="../../../images/zzz/guides/validation.webp"
          alt="Guide"
        />
        <p>
          This mode is basically the Zenless Zone Zero equivalent of Cavern of
          Corrosion from Honkai: Star Rail.
        </p>
        <ul>
          <li>
            These are your gear farming stages, each stage offers a combination
            of 2 different Drive Disks as well as EXP used to upgrade them or
            exchange at the music shop.
          </li>
          <li>
            It costs <strong>60 Battery Charges</strong> to do one stage.
          </li>
        </ul>
        <h5>Expert Challenge</h5>
        <StaticImage src="../../../images/zzz/guides/expert.webp" alt="Guide" />
        <p>
          This mode is basically the Zenless Zone Zero equivalent of Stagnant
          Shadow from Honkai: Star Rail.
        </p>
        <ul>
          <li>
            Here you will farm materials used to ascend characters and upgrade
            the Core Skill of characters.
          </li>
          <li>
            There are different bosses here to farm, each dropping different
            materials, so make sure you're farming the right one.
          </li>
          <li>
            It costs <strong>40 Battery Charges</strong> to do one stage.
          </li>
        </ul>
        <h5>Reckless Challenge</h5>
        <StaticImage
          src="../../../images/zzz/guides/reckless.webp"
          alt="Guide"
        />
        <p>
          This mode is basically the Zenless Zone Zero equivalent of Echo of War
          from Honkai: Star Rail.
        </p>
        <ul>
          <li>
            These are your weekly boss stages that reward W-Engine EXP,
            materials for Advanced Core Skill upgrades for characters, and
            currency used to buy W-Engines.
          </li>
          <li>
            You can only do 3 Reckless Challenge runs every week. However, you
            can do the same Reckless Challenge stage multiple times.
          </li>
          <li>
            You can also bring your friends to do this mode together since it
            offers a co-op mode as well.
          </li>
          <li>
            It costs <strong>40 Battery Charges</strong> to do one Reckless
            Challenge.
          </li>
        </ul>
        <SectionHeader title="Hollow Deep Dive Zone" />
        <StaticImage src="../../../images/zzz/guides/hollow.webp" alt="Guide" />
        <p>
          This is the <strong>Main Menu</strong> of the gameplay section. From
          here you can access story, combat commissions, exploration commissions
          as well as Shiyu Defense, Hollow Zero and Rally Commission.
        </p>
        <p>
          Each story chapter has a different map, including combat commissions
          and exploration commissions. To progress to the next story chapter,
          you will have to collect tokens that drop from successfully clearing
          story, combat and exploration commissions, each stage can drop 3
          tokens.
        </p>
        <SectionHeader title="Shiyu Defense" />
        <StaticImage src="../../../images/zzz/guides/shiyu.webp" alt="Guide" />
        <p>
          This mode is basically the Zenless Zone Zero equivalent of Memory of
          Chaos from Honkai: Star Rail.
        </p>
        <p>
          Shiyu Defense is a game mode where the objective is to defeat a group
          of enemies in limited amount of time. After completing a Shiyu Defense
          stage you will be awarded between 0 to 3 stars for that stage
          depending on how long it took you to defeat the enemies in that stage.
          The more stars that you are awarded, the better the rewards you
          receive.
        </p>
        <p>
          It is a more challenging game mode with the final stages being
          considered the <strong>end game content.</strong>
        </p>
        <ul>
          <li>
            Shiyu Defense consists of 10 stages and reward 300 Polychromes each
            upon clearing it with 3 stars.
          </li>
          <li>Later stages will require 2 teams of 3 units to enter.</li>
          <li>
            Shiyu Defense resets periodically, allowing the player to complete
            them again for Polychromes.
          </li>
        </ul>
        <SectionHeader title="Hollow Zero" />
        <StaticImage
          src="../../../images/zzz/guides/hollow_zero.webp"
          alt="Guide"
        />
        <p>
          This mode is basically the Zenless Zone Zero equivalent of Simulated
          Universe from Honkai: Star Rail.
        </p>
        <p>
          Hollow Zero is a roguelike game mode where the player will select a
          team of 3 Characters to venture inside a Hollow Zone to collect a
          random set of buffs that they can use to enhance their team and fight
          the enemies inside. The player will progress through zones made out of
          TVs that have both positive and negative encounters with the goal of
          defeating the boss in the final zone. Each time when you start a new
          run, you will only start with 1 Character out of the 3 picked, rest of
          the Characters will be available to obtain from encounters in the
          zones.
        </p>
        <ul>
          <li>
            <strong>Corruption</strong> is a debuff that gets accumulated when
            you move through the zones, you can lower it by interacting with
            specific nodes. Upon getting corrupted, you will gain a debuff which
            is also removable.
          </li>
          <li>
            This game mode{' '}
            <strong>
              does not require the player to spend any Battery Charges
            </strong>{' '}
            to play it and there is no limit to how many times the player can
            start a run in Hollow Zero.
          </li>
          <li>
            Resets each week, has weekly commissions that give you Polychromes
            and other materials used for upgrading Bangboos.
          </li>
          <li>
            There is also a <strong>License Pass</strong> which gives Inter-Knot
            EXP, Polychromes and other materials mostly used for anything
            Bangboo related. The pass is leveled by obtaining Investigation
            Points which are limited to 5000 per week.
          </li>
        </ul>
        <SectionHeader title="Rally Commission" />
        <StaticImage src="../../../images/zzz/guides/rally.webp" alt="Guide" />
        <p>
          This is another weekly mode where you enter a stage thats filled with
          combat challenges. After clearing the initial 2 stages, you unlock a
          weekly stage.
        </p>
        <p>
          Players can obtain Inter-Knot EXP, Coup-En, Disc EXP and Dennies each
          week.
        </p>
        <p>
          There is also a one-time stage clear reward which gives Inter-Knot
          EXP, Polychromes, Coup-En, Disc EXP, Dennies and Mini Cargo Trucks.
        </p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
    </DashboardLayout>
  );
};

export default ZZZGameModes;

export const Head: React.FC = () => (
  <Seo
    title="Game Modes | Zenless Zone Zero | Prydwen Institute"
    description="All available game modes in Zenless Zone Zero showcased."
    game="zzz"
  />
);
